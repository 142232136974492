@import 'checkboxes';

.profile-dropdown {
  max-width: 15.625rem !important;
  width: 15.625rem;
}

.routing-button .mdc-button {
  padding-top: 0.5rem !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
