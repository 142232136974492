.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}

.cropper-view-box {
  outline: 0;
  box-shadow: 0 0 0 0.063rem #39f;
}

.crop-actions{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
}