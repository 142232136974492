@import 'utilities/variables';

.round-card {
  border-radius: $bbraun-border-radius;
  box-sizing: border-box;
  background: $bbraun-space;
  box-shadow: $bbraun-shadow-m;
  text-align: center;
  height: 100%;
  min-height: 35.375rem;
  position: relative;
}

.details {
  align-self: stretch;
}

.text-align-start {
  text-align: start;
}
